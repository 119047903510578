import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import { Card, IconButton, Link, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { mdiArrowUp, mdiPencil } from "@mdi/js";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { tooltipStyles } from "../vue-grid/components/VueActionBar";
import { VueMapAndMediaSection } from "./components/VueMapAndMediaSection";
import { VueSurveySection } from "./components/VueSurveySection";
import { useNavigate, useParams } from "react-router-dom";
import { linkBoldStyle, linkElementStyle } from "../styles/VueStyles";
import { ScrollToTopButton } from "./components/ScrollToTopButton";
import { TagVueDialogContent } from "./components/TagVueDialogContent";
import { GENERIC_ERROR_MESSAGE, RoutePath } from "../../../../../constants";
import { metaDataDialogStyle } from "../styles/VueDetailStyles";
import {
  cancelVue,
  cancelVueSubmission,
  completeVueSubmission,
  failVueSubmission,
  fetchIndividualVueWithCanonicalID,
  getMediaTags,
  reissueVues,
  rejectToVuerVueSubmission,
  setVuesAsUrgent,
  takeBackVueSubmission,
  updateVueSubmissionPending,
} from "pages/dashboard/home/vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  IndividualVue,
  VueHistoryType,
  VueReferral,
  VueUrgent,
} from "./utils/vue_detail_interface";
import { CustomIndicator } from "pages/components/CustomIndicator";
import {
  ImmutableVueTags,
  getVueStatus,
} from "pages/dashboard/home/vues/vue-grid/utils/enums";
import { DeEscalateDialogContent } from "./components/DeEscalateDialogContent";
import { useVueProvider } from "../context/VueProvider";
import { IndividualVueActionType } from "./utils/reducer";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { BatchItem, VueExportItem } from "../vue-grid/types/types";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { getExporVueItemFromVue } from "../vue-grid/helpers/action_bar_dialogs";
import { alphanumericSort } from "utils/helpers/extensions";
import EditVueDetailsDialogContent, {
  VueDetail,
} from "./components/EditVueDetailsDialogContent";
import { ViewAttachmentsDialogContent } from "./components/ViewAttachmentsDialogContent";
import { TagItem } from "./components/TagPhotoDialogContent";
import {
  AssignSiteNameDialog,
  ExportDialogContent,
  ExportModalType,
} from "@ivueit/vue-engine";
import {
  VueDetailActionBar,
  VueDetailActionType,
} from "./components/VueDetailActionBar";
import { VueDetailActionsDialogContent } from "./components/VueDetailActionsDialogContent";
import { VueDetailMetaDataSection } from "./components/VueDetailMetaDataSection";
import { VueHistoryDialog } from "./components/VueHistoryDialog";
import { useAuth } from "context/AuthProvider";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { UserRoles } from "pages/portal/types/types";
import { hasMinimumRequiredRole } from "utils/helpers/common";
import Icon from "@mdi/react";
import MakeUrgentDialog from "../vue-grid/components/MakeUrgentDialog";

const VueDetails = () => {
  /// To get canonical ID from the URL
  const params = useParams();
  const { canonicalId } = params;
  /// Loader
  const [showLoader, setShowLoader] = useState(false);
  /// For scrolling
  const containerRef = useRef<HTMLDivElement>(null);
  /// Handles the visibility of "Scroll To Top" button
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  /// State to handle the tag vue dialog
  const [showTagVueDialog, setShowTagVueDialog] = useState<boolean>(false);
  /// State to handle the export vue dialog
  const [showExportVueDialog, setShowExportVueDialog] =
    useState<boolean>(false);
  /// State to handle the de-escalate vue dialog
  const [showDeEscalateVueDialog, setShowDeEscalateVueDialog] =
    useState<boolean>(false);
  /// State to handle the reissue vue dialog
  const [showReissueVueDialog, setShowReissueVueDialog] =
    useState<boolean>(false);
  /// State to handle the "View Metadata" dialog
  const [showMetadataDialog, setShowMetadataDialog] = useState<boolean>(false);
  /// State to handle the "View Attachments" dialog
  const [showAttachmentsDialog, setShowAttachmentsDialog] =
    useState<boolean>(false);
  /// State to handle the "Make Urgent" dialog
  const [showMakeUrgentDialog, setShowMakeUrgentDialog] =
    useState<boolean>(false);
  /// Edit Dialog
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showAssignSiteName, setShowAssignSiteName] = useState<boolean>(false);
  const [actionType, setActionType] = useState<VueDetailActionType>();
  const [openActionDialog, setOpenActionDialog] = useState<boolean>(false);
  const [openStatusDialog, setOpenStatusDialog] = useState<boolean>(false);
  /// Context value
  const {
    individualVue,
    dispatchIndividualVueAction,
    storeCompanyMediaTags,
  }: {
    individualVue: IndividualVue;
    dispatchIndividualVueAction: Function;
    storeCompanyMediaTags: Function;
  } = useVueProvider();
  const [vueTagsWithImmmutableTags, setVueTagsWithImmmutableTags] = useState<
    string[]
  >([]);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const navigateFromDetails = useNavigate();
  const auth = useAuth();
  const { getAvailablePortalsForUser, userData } = auth;
  // Sets the companyId
  const parentCompanyId = userData.companyId;
  // List of companies that are not read only for the user
  const availablePortals: Company[] = getAvailablePortalsForUser();
  const accountManager =
    (individualVue.companyId &&
      availablePortals.filter(
        (portal) => portal.id === individualVue.companyId
      )[0].accountManager) ??
    "";

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        setShowScrollToTopButton(container.scrollTop > 0);
      };
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    /// Get tags with Immutable tags
    const getTagsWithImmutableTags = () => {
      var immutableTags = getImmutableTagsList();
      const availableTags = [...immutableTags, ...vueTags].sort((a, b) =>
        alphanumericSort(a, b)
      );
      setVueTagsWithImmmutableTags(availableTags);
    };
    getTagsWithImmutableTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualVue]);

  const fetchVueDetail = async (refreshForExport: boolean) => {
    setShowLoader(true);
    const response = await fetchIndividualVueWithCanonicalID(canonicalId);
    if (response.status === WebServiceStatus.success) {
      const vueDetail = response.data as IndividualVue;
      dispatchIndividualVueAction({
        type: IndividualVueActionType.loadVueData,
        payload: vueDetail,
      });
    } else {
      setSnackbarContent({
        title: "Attention!!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
    // NOTE: As a temporary solution for generating pdf with latest changes,
    // the vue will be reloaded before the export dialog is displayed.
    // This flow will be altered once feature to send export through email is ready
    if (refreshForExport) {
      setShowExportVueDialog(true);
    }
  };

  /// Status/History Dialog
  const getStatusDialog = () => {
    return (
      <VueHistoryDialog
        openDialog={openStatusDialog}
        handleCloseClick={() => {
          setOpenStatusDialog(false);
        }}
        title={title}
        type={VueHistoryType.editHistory}
        vueId={id}
      />
    );
  };

  useEffect(() => {
    fetchVueDetail(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canonicalId]);

  const {
    id,
    escalated,
    description,
    notes,
    cost,
    canUseReferral,
    siteNameNumber,
    title,
    streetAddress,
    addressTwo,
    startsAt,
    endsAt,
    isInternal,
    meta,
    batchName,
    batchID,
    surveyName,
    surveyTemplateId,
    backgroundCheckRequired,
    vueTags,
    city,
    stateProvinceAbbreviation,
    zipPostalCode,
    countryAbbreviation,
    urgent,
    highQualityRequired,
    jobSiteId,
    fileAttachmentIDs,
  } = individualVue.vue;

  const isCompletedVue =
    getVueStatus(individualVue.status).toUpperCase() === "COMPLETED";

  const hasEscalatedMedias = () => {
    const adminEscalatedPhotos = individualVue.photos.filter(
      (photo) => photo.escalated && photo.adminEscalated
    );
    const clientEscalatedPhotos = individualVue.photos.filter(
      (photo) => photo.escalated && !photo.adminEscalated
    );
    const adminEscalatedVideos = individualVue.videos.filter(
      (video) => video.escalated && video.adminEscalated
    );
    const clientEscalatedVideos = individualVue.videos.filter(
      (video) => video.escalated && !video.adminEscalated
    );
    return (
      escalated ||
      adminEscalatedPhotos.length > 0 ||
      clientEscalatedPhotos.length > 0 ||
      adminEscalatedVideos.length > 0 ||
      clientEscalatedVideos.length > 0
    );
  };

  const getImmutableTagsList = () => {
    var tagsUpdated = [];
    if (isInternal) tagsUpdated.push(ImmutableVueTags.internal);
    if (urgent) tagsUpdated.push(ImmutableVueTags.urgent);
    if (highQualityRequired) tagsUpdated.push(ImmutableVueTags.highQuality);
    if (hasEscalatedMedias()) tagsUpdated.push(ImmutableVueTags.escalated);
    return tagsUpdated;
  };

  const handleScrollToTopButtonClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  /// Handles the onClick of "TagVue" button
  const handleTagVueButtonClick = () => {
    setShowTagVueDialog(true);
  };

  /// Handles the onClick of "ExportVue" button
  const handleExportVueButtonClick = () => {
    // NOTE: As a temporary solution for generating pdf with latest changes,
    // the vue will be reloaded before the export dialog is displayed.
    // This flow will be altered once feature to send export through email is ready
    fetchVueDetail(true);
  };

  /// Handles the onClick of "View Metadata" button
  const handleViewMetadataButtonClick = () => {
    setShowMetadataDialog(true);
  };

  /// Handles the onClick of "View Attachments" button
  const handleViewAttachmentsButtonClick = () => {
    setShowAttachmentsDialog(true);
  };

  /// This returns the Tags for the vue
  const getTags = (tagsList: string[]) => {
    return (
      <>
        {tagsList.slice(0, 3).map((tag: string, index: number) => (
          <CustomBadge
            key={index}
            content={tag}
            borderRadius="8px"
            sx={{
              ".MuiBadge-badge": {
                background: "#c7ccd0 !important",
                padding: "12px 8px",
                minWidth: "50px",
              },
            }}
          />
        ))}
        {tagsList.length > 3 && (
          <Tooltip
            title={tagsList.slice(3).map((tag: string, index: number) => {
              return (
                <span key={index}>
                  <b key={tag}>
                    {tag}
                    <br />
                  </b>
                </span>
              );
            })}
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={false}
          >
            <span>
              <MDTypography
                sx={{
                  pl: 0.8,
                  fontSize: "14px",
                  fontWeight: "regular",
                }}
              >
                +{tagsList.length - 3} more
              </MDTypography>
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  /// Tag vue dialo
  const getTagVueDialog = () => {
    return (
      <CustomDialogBox
        title="Tag Vue"
        width="440px"
        openDialog={showTagVueDialog}
      >
        <TagVueDialogContent
          vueId={id}
          previouslyAddedTags={vueTagsWithImmmutableTags.map((tag) => tag)}
          cancelButtonClick={() => {
            setShowTagVueDialog(false);
          }}
          saveButtonClick={(selectedTagsList: string[]) => {
            dispatchIndividualVueAction({
              type: IndividualVueActionType.editVueTags,
              payload: {
                ...individualVue,
                vue: {
                  vueTags: selectedTagsList,
                },
              },
            });
            setShowTagVueDialog(false);
          }}
          immutableTags={getImmutableTagsList()}
        />
      </CustomDialogBox>
    );
  };

  const getViewMetadataDialog = () => {
    return (
      <CustomDialogBox title="Metadata" openDialog={showMetadataDialog}>
        <MDBox sx={metaDataDialogStyle}>
          <ModalPopUp
            content={JSON.stringify(meta)}
            closeDialog={() => {
              setShowMetadataDialog(false);
            }}
          />
        </MDBox>
      </CustomDialogBox>
    );
  };

  const getViewAttachmentsDialog = () => {
    return (
      <CustomDialogBox
        width="500px"
        title="View Attachments"
        openDialog={showAttachmentsDialog}
        sx={{
          ".MuiDialogContent-root": {
            padding: "36px 46px",
            borderBottom: "1px solid rgb(168, 184, 216)",
          },
        }}
      >
        <ViewAttachmentsDialogContent
          closeDialog={() => setShowAttachmentsDialog(false)}
          listOfFileIds={fileAttachmentIDs}
        />
      </CustomDialogBox>
    );
  };

  /// Export vue dialog
  const getExportVueDialog = () => {
    const selectedIds: string[] = [id];
    const vueItem: VueExportItem = getExporVueItemFromVue(individualVue.vue);
    const selectedVues: VueExportItem[] = [vueItem];
    return (
      <CustomDialogBox
        title="Export"
        width="560px"
        openDialog={showExportVueDialog}
      >
        <ExportDialogContent
          excludedVueIds={[]}
          excludedBatchIds={[]}
          closeDialog={() => setShowExportVueDialog(false)}
          type={ExportModalType.vues}
          hasNonCompletedVues={!isCompletedVue}
          selectedItemIds={selectedIds}
          selectedVues={selectedVues}
        />
      </CustomDialogBox>
    );
  };

  const getDeEscalateDialog = () => {
    return (
      <CustomDialogBox
        title="Escalated Photos"
        width="670px"
        openDialog={showDeEscalateVueDialog}
      >
        <DeEscalateDialogContent
          closeDialog={() => {
            setShowDeEscalateVueDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getMakeUrgentDialog = () => {
    return (
      <CustomDialogBox title="Make Urgent" openDialog={showMakeUrgentDialog}>
        <MakeUrgentDialog
          onCancelClick={() => {
            setShowMakeUrgentDialog(false);
          }}
          message={
            "Are you sure you want to mark this Vue as urgent? Doing so will increase the price of the Vue."
          }
          onMakeUrgentClick={onMakeUrgentButtonClick}
        />
      </CustomDialogBox>
    );
  };

  const getReissueDialog = () => {
    return (
      <CustomDialogBox title="Reissue" openDialog={showReissueVueDialog}>
        <ModalPopUp
          content={`Are you sure you want to reissue this Vue?`}
          actions={getReIssueActions()}
        />
      </CustomDialogBox>
    );
  };

  const reissueVue = async () => {
    setShowLoader(true);
    const response = await reissueVues([id]);
    if (response.status === WebServiceStatus.success) {
      const batchesList = response.data.batches as BatchItem[];
      if (batchesList.length !== 0) {
        setSnackbarContent({
          title: "Vue Reissued",
          message: "Vue reissued successfully.",
          isError: false,
        });
      } else {
        setSnackbarContent({
          title: "Vue Reissue Failed",
          message: "Vues cannot be reissued.",
          isError: true,
        });
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
    setShowReissueVueDialog(false);
  };

  const getReIssueActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={() => {
          setShowReissueVueDialog(false);
        }}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => {
          reissueVue();
        }}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        REISSUE
      </MDButton>,
    ];
  };

  const onClickLinkElement = (path: string, params?: any) => {
    navigateFromDetails(path, {
      state: params,
    });
  };

  const getEditVueDetailsDialog = () => {
    const vueDetail: VueDetail = {
      id: id,
      address: `${streetAddress}, ${city}, ${stateProvinceAbbreviation} ${zipPostalCode}, ${countryAbbreviation}`,
      addressTwo: addressTwo,
      notes: notes,
      startsAt: startsAt.parsedTimeFromBackend(false),
      endsAt: endsAt.parsedTimeFromBackend(false),
      cost: cost,
      isUrgent: urgent ? VueUrgent.VUE_URGENT : VueUrgent.VUE_NOT_URGENT,
      canUseReferral: canUseReferral
        ? VueReferral.EDIT_TO_USE_REFERRAL
        : VueReferral.EDIT_TO_NOT_USE_REFERRAL,
      // TODO : These values are not available in the Individual Vue API.
      // Need to update it when API is ready
      payout: "0",
      claimTimeLimitInMinutes: "0",
    };
    return (
      <CustomDialogBox
        title="Edit Vue Details"
        width="1204px"
        openDialog={showEditDialog}
      >
        <EditVueDetailsDialogContent
          closeDialog={() => {
            setShowEditDialog(false);
          }}
          onUpdateSuccess={async () => {
            await fetchVueDetail(false);
          }}
          vueDetail={vueDetail}
        />
      </CustomDialogBox>
    );
  };

  const canEnableMakeUrgent = (): boolean => {
    return (
      !urgent &&
      individualVue.status.toLowerCase() === "SUBMISSION_OPEN".toLowerCase()
    );
  };

  const onCloseMakeUrgentDialog = () => {
    setShowMakeUrgentDialog(false);
  };

  const onMakeUrgentButtonClick = async () => {
    if (!id) {
      return;
    }
    const response = await setVuesAsUrgent([id]);
    if (response.status === WebServiceStatus.success) {
      onCloseMakeUrgentDialog();
      await fetchVueDetail(false);
      setSnackbarContent({
        title: "Make Urgent",
        message: "Vue marked as Urgent successfully.",
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
      onCloseMakeUrgentDialog();
    }
  };

  const getSiteNameSection = () => {
    return (
      <MDBox display="flex" alignItems="center" mb={0.5}>
        <MDTypography variant="h6" fontWeight="bold" sx={{ color: "#344767" }}>
          Site Name:
        </MDTypography>
        <MDBox sx={{ ":not(:hover) .MuiIconButton-root": { display: "none" } }}>
          {jobSiteId.isNotEmpty() ? getSiteNameNumber() : getAssignButton()}
          {/* Should show only when hovering the button */}
          {
            <IconButton
              color="primary"
              size="small"
              onClick={onClickAssignButton}
            >
              <Icon color="#1b66dc" path={mdiPencil} size={0.72} />
            </IconButton>
          }
        </MDBox>
      </MDBox>
    );
  };

  const getAssignButton = () => {
    return (
      <Link
        sx={{ ...linkElementStyle, ...linkBoldStyle }}
        color="primary"
        variant="h6"
        underline="always"
        pl={1}
        onClick={onClickAssignButton}
      >
        ASSIGN
      </Link>
    );
  };

  const getSiteNameNumber = () => {
    return (
      <Link
        sx={{ ...linkElementStyle, fontWeight: "500", cursor: "pointer" }}
        color="primary"
        variant="h6"
        underline="always"
        pl={1}
        onClick={() => {
          onClickLinkElement(`${RoutePath.vues}/${canonicalId}/site-details`, {
            jobSiteID: jobSiteId,
            siteName: siteNameNumber,
          });
        }}
        pr={1}
      >
        {siteNameNumber}
      </Link>
    );
  };

  const onClickAssignButton = () => {
    setShowAssignSiteName(true);
  };

  const onActionClick = async (reason: string) => {
    setShowLoader(true);
    const webService =
      actionType === VueDetailActionType.sendBackToVuer
        ? rejectToVuerVueSubmission(individualVue.submissionId, reason)
        : actionType === VueDetailActionType.markPending
        ? updateVueSubmissionPending(individualVue.submissionId, reason)
        : actionType === VueDetailActionType.fail
        ? failVueSubmission(individualVue.submissionId, reason)
        : actionType === VueDetailActionType.takeBack
        ? takeBackVueSubmission(individualVue.submissionId, reason)
        : actionType === VueDetailActionType.cancel
        ? // If submissionId is available, cancel the submission or else cancel the Vue
          individualVue.submissionId
          ? cancelVueSubmission(individualVue.submissionId, reason)
          : cancelVue(id, reason)
        : null;
    const response = await webService;
    if (response.status === WebServiceStatus.success) {
      await fetchVueDetail(false);
      setOpenActionDialog(false);
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const fetchDialogContent = () => {
    let title: string =
      actionType === VueDetailActionType.sendBackToVuer
        ? "SEND BACK TO VUER"
        : actionType === VueDetailActionType.markPending
        ? "MARK PENDING"
        : actionType === VueDetailActionType.fail
        ? "FAIL VUE"
        : actionType === VueDetailActionType.takeBack
        ? "TAKE BACK"
        : actionType === VueDetailActionType.cancel
        ? "CANCEL VUE"
        : "";
    let description: string =
      actionType === VueDetailActionType.sendBackToVuer
        ? "Are you sure you want to send this Vue back to the Vuer?"
        : actionType === VueDetailActionType.markPending
        ? "Are you sure you want to mark this Vue as pending?"
        : actionType === VueDetailActionType.fail
        ? "Are you sure you want to fail this Vue?"
        : actionType === VueDetailActionType.takeBack
        ? "Are you sure you want to take this Vue back from the Vuer?"
        : actionType === VueDetailActionType.cancel
        ? "Are you sure you want to cancel this vue?"
        : "";
    return (
      <CustomDialogBox title={title} openDialog={openActionDialog}>
        <VueDetailActionsDialogContent
          description={description}
          onVueAction={(reason: string) => {
            onActionClick(reason);
          }}
          onClose={() => {
            setOpenActionDialog(false);
          }}
          buttonName={title}
        />
      </CustomDialogBox>
    );
  };

  const handleCompleteVue = async () => {
    setShowLoader(true);
    const response = await completeVueSubmission(individualVue.submissionId);
    if (response.status === WebServiceStatus.success) {
      await fetchVueDetail(false);
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const handleActionClick = (type: VueDetailActionType) => {
    switch (type) {
      case VueDetailActionType.export:
        handleExportVueButtonClick();
        break;
      case VueDetailActionType.editVue:
        setShowEditDialog(true);
        break;
      case VueDetailActionType.reissue:
        setShowReissueVueDialog(true);
        break;
      case VueDetailActionType.escalated:
        setShowDeEscalateVueDialog(true);
        break;
      case VueDetailActionType.makeUrgent:
        setShowMakeUrgentDialog(true);
        break;
      case VueDetailActionType.tagVue:
        handleTagVueButtonClick();
        break;
      case VueDetailActionType.viewMetadata:
        handleViewMetadataButtonClick();
        break;
      case VueDetailActionType.viewAttachments:
        handleViewAttachmentsButtonClick();
        break;
      case VueDetailActionType.editsHistory:
        setOpenStatusDialog(true);
        break;
      case VueDetailActionType.complete:
        handleCompleteVue();
        break;
      case VueDetailActionType.sendBackToVuer:
      case VueDetailActionType.markPending:
      case VueDetailActionType.takeBack:
      case VueDetailActionType.cancel:
      case VueDetailActionType.fail:
        setActionType(type);
        setOpenActionDialog(true);
        break;
      default:
        break;
    }
  };

  const getAssignSiteNameDialog = () => {
    return (
      <CustomDialogBox
        title="Assign Site Name"
        width="400px"
        openDialog={showAssignSiteName}
      >
        <AssignSiteNameDialog
          vueId={id}
          onClickCancel={() => {
            setShowAssignSiteName(false);
          }}
          onClickAssign={async () => {
            await fetchVueDetail(false);
            setShowAssignSiteName(false);
          }}
          previouslySelectedSite={getPreviouslySelectedSite()}
          parentCompanyId={parentCompanyId}
        />
      </CustomDialogBox>
    );
  };

  const getPreviouslySelectedSite = (): any => {
    if (jobSiteId.isNotEmpty()) {
      return { siteNameNumber: siteNameNumber, siteId: jobSiteId };
    }
    return null;
  };

  /// Fetching Photo tag names from the list of IDs
  const fetchCompanyMediaTags = useCallback(async () => {
    const response = await getMediaTags();
    if (response.status === WebServiceStatus.success) {
      const tagListForMyCompany = response.data.tags as TagItem[];
      storeCompanyMediaTags(tagListForMyCompany);
    } else {
      storeCompanyMediaTags([]);
    }
  }, [storeCompanyMediaTags]);

  useEffect(() => {
    fetchCompanyMediaTags();
  }, [fetchCompanyMediaTags]);

  return (
    <DashboardContentLayout
      needCardBackground={false}
      containerRef={containerRef}
    >
      {showLoader && <CustomIndicator />}
      {showTagVueDialog && getTagVueDialog()}
      {openStatusDialog && getStatusDialog()}
      {openActionDialog && fetchDialogContent()}
      {showReissueVueDialog && getReissueDialog()}
      {showExportVueDialog && getExportVueDialog()}
      {showEditDialog && getEditVueDetailsDialog()}
      {showMakeUrgentDialog && getMakeUrgentDialog()}
      {showMetadataDialog && getViewMetadataDialog()}
      {showAssignSiteName && getAssignSiteNameDialog()}
      {showDeEscalateVueDialog && getDeEscalateDialog()}
      {showAttachmentsDialog && getViewAttachmentsDialog()}
      {/* Top section fixed */}
      <MDBox
        display="flex"
        position="sticky"
        top="0px"
        zIndex="132"
        sx={{ backgroundColor: "#f0f2f5" }}
      >
        {/* Left column */}
        <MDBox flexGrow="1" pl={2} pb={1.4}>
          <MDBox display="flex" alignItems="center" mb={0.8}>
            <MDTypography variant="h5" color="black">
              {title}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.8}>
            <MDTypography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#344767" }}
            >
              Company:
            </MDTypography>
            <MDTypography
              variant="h6"
              color="#344767"
              pl={1}
              sx={{ fontWeight: "500" }}
            >
              {individualVue.companyName}
            </MDTypography>
            {canUseReferral && (
              <MDBox ml={1}>
                <CustomBadge content={"Referral"} />
              </MDBox>
            )}
            {backgroundCheckRequired && (
              <MDBox ml={1}>
                <CustomBadge content={"Background Check Required"} />
              </MDBox>
            )}
          </MDBox>
          {accountManager && (
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDTypography
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#344767" }}
              >
                iVueit Account Manager:
              </MDTypography>
              <MDTypography
                variant="h6"
                color="#344767"
                pl={1}
                sx={{ fontWeight: "500" }}
              >
                {accountManager}
              </MDTypography>
            </MDBox>
          )}
          {getSiteNameSection()}
          <MDBox display="flex" alignItems="center" mb={1.2}>
            <MDTypography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#344767" }}
            >
              Survey Name:
            </MDTypography>
            <Link
              sx={{ ...linkElementStyle, fontWeight: "500", cursor: "pointer" }}
              onClick={() => {
                onClickLinkElement(RoutePath.vues, {
                  surveyTemplateId: surveyTemplateId,
                });
              }}
              variant="h6"
              color="primary"
              underline="always"
              pl={1}
            >
              {surveyName}
            </Link>
          </MDBox>
          {batchName && (
            <MDBox display="flex" alignItems="center" mt={0.6}>
              <MDTypography
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#344767" }}
              >
                Batch Name:
              </MDTypography>
              <Link
                sx={{
                  ...linkElementStyle,
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onClickLinkElement(RoutePath.vues, {
                    batchID: batchID,
                  });
                }}
                variant="h6"
                color="primary"
                underline="always"
                pl={1}
              >
                {batchName}
              </Link>
            </MDBox>
          )}
          <MDBox display="flex" alignItems="center" mt={2}>
            {vueTagsWithImmmutableTags.length > 0 && (
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="h6"
                  color="dark"
                  sx={{ fontWeight: "600", marginRight: "6px" }}
                >
                  Vue Tags:
                </MDTypography>
              </MDBox>
            )}
            {getTags(vueTagsWithImmmutableTags)}
          </MDBox>
        </MDBox>
        {/* Right column */}
        <VueDetailActionBar
          vueStatus={individualVue.status}
          showEscalatedButton={hasEscalatedMedias()}
          showAttachments={fileAttachmentIDs.length !== 0}
          onClick={(type: VueDetailActionType) => {
            handleActionClick(type);
          }}
          disableMakeUrgent={!canEnableMakeUrgent()}
          isVueAlreadyMarkedAsUrgent={urgent}
        />
      </MDBox>
      {/* Meta section */}
      <MDBox display="flex" alignItems="left" pb={1.4}>
        <Card sx={{ width: "100%" }}>
          <VueDetailMetaDataSection
            individualVue={individualVue}
            canonicalID={canonicalId}
          />
        </Card>
      </MDBox>
      {/* Map and Photo Section */}
      <Card sx={{ p: "20px", mt: "10px" }}>
        <VueMapAndMediaSection vueDetail={individualVue} />
      </Card>
      {/* Survey Section */}
      <Card
        sx={{
          mt: "20px",
          ".MuiCardMedia-media, video": {
            maxHeight: "343px",
            marginLeft: "auto !important",
            marginRight: "auto !important",
          },
          video: {
            height: "343px !important",
          },
          ".heightFix": {
            height: "343px !important",
          },
        }}
      >
        <VueSurveySection
          surveyTemplateId={surveyTemplateId}
          submissionId={individualVue.submissionId}
          vueOverview={description}
          vueDescription={notes}
        />
      </Card>
      {/* ScrollToTop */}
      {showScrollToTopButton && (
        <ScrollToTopButton
          iconPath={mdiArrowUp}
          handleOnClick={handleScrollToTopButtonClick}
        />
      )}
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};

export default VueDetails;
