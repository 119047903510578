import MDBox from "components/MDBox";
import VueDescription from "./VueDescription";
import { mdiTextBoxOutline } from "@mdi/js";
import { getVueStatus } from "../../vue-grid/utils/enums";
import { IndividualVue, VueHistoryType } from "../utils/vue_detail_interface";
import { getDay } from "../helpers/helper_methods";
import { useState } from "react";
import { VueHistoryDialog } from "./VueHistoryDialog";

interface Props {
  individualVue: IndividualVue;
  canonicalID: string;
}

export const VueDetailMetaDataSection = (props: Props) => {
  const [openStatusDialog, setOpenStatusDialog] = useState<boolean>(false);
  const {
    title,
    arRequired,
    typeOf,
    cost,
    slaActual,
    slaTarget,
    selectedDays,
    startsAt,
    endsAt,
    createdAt,
    expiresAt,
    isInternal,
    streetAddress,
    addressTwo,
    city,
    stateProvinceAbbreviation,
    zipPostalCode,
    countryAbbreviation,
  } = props.individualVue.vue;
  const isCompletedVue =
    getVueStatus(props.individualVue.status).toUpperCase() === "COMPLETED";

  /// Status/History Dialog
  const getStatusDialog = () => {
    return (
      <VueHistoryDialog
        vueId={props.individualVue.vue.id}
        openDialog={openStatusDialog}
        handleCloseClick={() => {
          setOpenStatusDialog(false);
        }}
        title={title}
        type={VueHistoryType.statusHistory}
      />
    );
  };

  const getFormattedDateTime = (time: string) => {
    return (
      time?.formatUTCNanoSecondsToString("MM/DD/YYYY - hh:mm A", true) ?? ""
    );
  };

  const getSelectedDays = () => {
    const selectedDaysArray = JSON.parse(selectedDays).selectedDays as number[];
    const allDaysArray = [0, 1, 2, 3, 4, 5, 6];
    const areAllDaysSelected = allDaysArray.every((day) =>
      selectedDaysArray.includes(day)
    );
    return areAllDaysSelected
      ? "All"
      : selectedDaysArray.map((value) => getDay(value)).join(", ");
  };

  const getVuerCompletedValue = () => {
    return isCompletedVue && props.individualVue.vuerCompletedAt
      ? getFormattedDateTime(props.individualVue.vuerCompletedAt)
      : isCompletedVue && props.individualVue.lastUpdatedAt
      ? getFormattedDateTime(props.individualVue.lastUpdatedAt)
      : "N/A";
  };

  const getAddress = () => {
    const addressComponents = [
      streetAddress,
      addressTwo,
      city,
      stateProvinceAbbreviation,
      zipPostalCode,
      countryAbbreviation,
    ];
    // Filter out undefined or empty components
    const validComponents = addressComponents.filter(
      (component) => component && component.trim() !== ""
    );
    // Construct the address string by joining valid components with commas
    const fullAddress = validComponents.join(", ");
    // If there are valid components, use the constructed address; otherwise, set it to an empty string
    return validComponents.length > 0 ? fullAddress : "";
  };

  return (
    <MDBox>
      {openStatusDialog && getStatusDialog()}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        px={2}
        py={2}
        sx={{ overflowX: "auto" }}
      >
        <VueDescription title="Vue ID" value={`#${props.canonicalID}`} />
        <VueDescription
          title="STATUS"
          value={getVueStatus(props.individualVue.status).capitalizeWords()}
          iconPath={mdiTextBoxOutline}
          onClick={() => {
            setOpenStatusDialog(true);
          }}
        />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="Pending Notes" value={"-"} />
        <VueDescription
          title="Type"
          value={typeOf.capitalizeWords()}
          tagName={arRequired ? "AR" : ""}
        />
        <VueDescription title="Internal" value={isInternal ? "YES" : "NO"} />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="Vuer" value={"-" ?? "None"} />
        <VueDescription title="Address" value={getAddress()} maxWidth="250px" />
        <VueDescription title="Cost" value={`$${cost}`} />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="Payout" value={"-"} />
        <VueDescription
          title="Age/SLA"
          value={`${slaActual}/${slaTarget} hrs`}
        />
        <VueDescription
          title="Publish At"
          value={getFormattedDateTime(createdAt)}
        />
        <VueDescription title="Days Available" value={getSelectedDays()} />
        <VueDescription
          title="Times Available"
          value={`${startsAt.parsedTimeFromBackend(
            true
          )} - ${endsAt.parsedTimeFromBackend(true)}`}
        />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription
          title="Claim Time Limit in Minutes"
          value={"-" ?? "N/A"}
        />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="AutoFail At" value={"-" ?? "N/A"} />
        <VueDescription
          title="Vuer Completed"
          value={getVuerCompletedValue()}
        />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="Completed" value={"-" ?? "N/A"} />
        <VueDescription
          title="Expires"
          value={(expiresAt && getFormattedDateTime(expiresAt.value)) ?? "N/A"}
        />
      </MDBox>
    </MDBox>
  );
};
