import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import {
  Icon,
  IconButton,
  MDBox,
} from "pages/dashboard/home/vues/vue-grid/helpers/imports";

const VueDescription = ({
  title,
  value,
  tagName,
  iconPath,
  onClick,
  maxWidth,
}: {
  title: string;
  value: string;
  tagName?: string;
  iconPath?: string;
  maxWidth?: string;
  onClick?: () => void;
}) => {
  const getTypographyStyle = () => {
    let style = {
      fontSize: "14px",
      fontWeight: "400",
      maxWidth: maxWidth ?? "100px",
    };
    if (iconPath) {
      style = { ...style, ...{ position: "relative", marginTop: "-2px" } };
    }
    return style;
  };

  return (
    <MDBox flexShrink="0" flexBasis="auto" flexGrow="1" marginLeft="-15px">
      <MDBox paddingLeft="15px" paddingRight="15px" marginBottom="8px">
        <MDBox display="flex">
          <MDTypography
            variant="h6"
            fontWeight="bold"
            color="dark"
            sx={{ letterSpacing: "0" }}
          >
            {title.toUpperCase()}
          </MDTypography>
          {iconPath && (
            <IconButton
              color="primary"
              onClick={onClick}
              sx={{ position: "relative", marginTop: "-6px" }}
            >
              <Icon path={iconPath} size={0.75} />
            </IconButton>
          )}
        </MDBox>
        <MDTypography variant="subtitle2" color="dark" sx={getTypographyStyle}>
          {value}
        </MDTypography>
        {tagName && (
          <CustomBadge
            content={tagName}
            sx={{
              "& .MuiBadge-badge": {
                color: "white !important",
                background: "#344767",
                textTransform: "unset",
              },
            }}
          />
        )}
      </MDBox>
    </MDBox>
  );
};

export default VueDescription;
