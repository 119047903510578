import { IconButton } from "@mui/material";
import { HeaderStepSection } from "./HeaderStepSection";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { mdiDelete } from "@mdi/js";
import { regularTextStyle, tagItemStyle } from "../styles/SurveyStyles";
import { CustomAddoption } from "./CustomAddOption";
import { StepActionType } from "../types/enums";
import { CustomPreviewComponent } from "./CustomPreviewComponent";
import MDInput from "components/MDInput";
import { Icon } from "pages/dashboard/home/vues/vue-grid/helpers/imports";
import {
  TEXTFIELD_CHANGE_DELAY,
  WebServiceStatus,
  customBadgeStyle,
  SurveyStep,
} from "@ivueit/vue-engine";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { updateSurveyTemplateStep } from "../services/SurveyServices";
import {
  VueSurveyData,
} from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import {
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
} from "../utils/helpers";

interface Props {
  onHeaderClick: (type: StepActionType) => void;
  stepNumber: string;
  hasBeenGrouped: boolean;
  hasConditionsApplied: boolean;
  showAddConditionButton: boolean;
  step: SurveyStep;
  groupName: string;
  onSuccess: (step: SurveyStep) => void;
  surveyData: VueSurveyData;
  onDeleteGroup: (id: string) => void;
  groupIndex: number;
}

export const DraftModeSelectComponent = ({
  onHeaderClick,
  stepNumber,
  hasBeenGrouped,
  hasConditionsApplied,
  showAddConditionButton,
  onSuccess,
  step,
  groupName,
  groupIndex,
  surveyData,
  onDeleteGroup,
}: Props) => {
  const [selectStep, setSelectStep] = useState<SurveyStep>(step);
  const [delayTypeAction, setDelayTypeAction] = useState<boolean>(false);
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const { id, description, options } = selectStep;
  const [error, setError] = useState<string>("");
  const parsedOptions: string[] = useMemo(
    () => (options ? JSON.parse(options).options : []),
    [options]
  );

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectStep({ ...selectStep, [event.target.name]: value });
    setError(!value ? "Prompt cannot be empty" : "");
    setDelayTypeAction(value.isNotEmpty());
  };

  const handleDeleteOption = (optionToBeDeleted: string) => {
    setDelayTypeAction(true);
    const filteredOptions = parsedOptions.filter(
      (item) => item.toLowerCase() !== optionToBeDeleted.toLowerCase()
    );
    const optionsObj = {
      options: filteredOptions,
    };
    setSelectStep({ ...selectStep, options: JSON.stringify(optionsObj) });
  };

  const handleAddOption = (newOptions: string[]) => {
    setDelayTypeAction(true);
    const optionsObj = {
      options: newOptions,
    };
    const stringifiedValue = JSON.stringify(optionsObj);
    setSelectStep({ ...selectStep, options: stringifiedValue });
  };

  const updateGroupIdForStep = () => {
    // Updating the groupId for this step
    setSelectStep({ ...selectStep, groupId: "" });
    setDelayTypeAction(true);
  };

  const deleteGroup = () => {
    const hasSurveyConditions = hasConditionsOnSurvey(surveyData, step);
    const hasStepConditions = hasConditionsOnSurveyStep(surveyData, step);
    // TODO - Allow/deny based on specific scenaario, for now deny all
    // when ungrouping, stepcondition could contain condition from step in group, if so deny
    // always deny if parent step of condition, as only other steps of same group could be linked
    if (hasSurveyConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot ungroup step.",
        isError: true,
      });
      return;
    }
    const islastStepInTheGroup = isGroupOrphaned(step, surveyData.survey.steps);
    const groupID = islastStepInTheGroup ? selectStep.groupId : "";
    if (groupID) {
      // Removing the group if the group is orphaned
      onDeleteGroup(groupID);
    }
    updateGroupIdForStep();
  };

  const handleUpdateSurveyStep = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await updateSurveyTemplateStep(id, selectStep);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as SurveyStep;
      onSuccess(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to update Select step.",
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStep, id]);

  useEffect(() => {
    if (delayTypeAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyStep();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyStep, delayTypeAction]);

  return (
    <MDBox p="20px">
      <HeaderStepSection
        stepNumber={stepNumber}
        groupName={groupName}
        stepType={"Select"}
        isGrouped={hasBeenGrouped}
        onButtonClick={(type: StepActionType) => {
          type === StepActionType.ungroup ? deleteGroup() : onHeaderClick(type);
        }}
        hasConditionApplied={hasConditionsApplied}
        showAddConditionButton={showAddConditionButton}
        groupIndex={groupIndex}
      />
      <MDBox
        border="1px solid #C7CCD0"
        p="16px 16px 20px 16px"
        borderRadius="8px"
      >
        <MDBox mb={1}>
          <MDInput
            fullWidth
            name="description"
            value={description}
            error={error}
            placeholder="Enter Prompt"
            multiline
            rows={2}
            onChange={handlePromptChange}
          />
        </MDBox>
        <MDBox mb={1}>
          <CustomAddoption
            onAddOption={handleAddOption}
            optionsList={parsedOptions}
            title="Add Option"
          />
        </MDBox>
        {parsedOptions.length > 0 && (
          <MDBox mb={1}>
            <MDTypography sx={regularTextStyle}>Options</MDTypography>
            <MDBox display="flex" flexWrap="wrap">
              {parsedOptions.map((option, index) => {
                return (
                  <MDBox
                    key={`${option}-${index}`}
                    display="flex"
                    sx={tagItemStyle}
                  >
                    <CustomBadge
                      content={`${index + 1}. ${option}`}
                      borderRadius="8px"
                      sx={customBadgeStyle}
                    />
                    <IconButton
                      color="error"
                      onClick={() => {
                        handleDeleteOption(option);
                      }}
                    >
                      <Icon path={mdiDelete} size={0.85} />
                    </IconButton>
                  </MDBox>
                );
              })}
            </MDBox>
          </MDBox>
        )}
        <MDBox mb={1}>
          <CustomPreviewComponent step={selectStep} />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};
