import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";

interface Props {
  company: Company;
  closeDialog: () => void;
  onSuccess: (updatedInfo: Company) => void;
}

const EditContactInfoDialogContent = (props: Props) => {
  const [companyInfo, setCompanyInfo] = useState(props.company);
  const [error, setError] = useState({
    accountManager: "",
    primaryEmail: "",
    billingEmail: "",
    address: "",
  });
  const { accountManager, primaryEmail, billingEmail, address } = companyInfo;

  const disableSaveButton = () => {
    return (
      (accountManager.trim() === props.company.accountManager.trim() &&
        primaryEmail.trim() === props.company.primaryEmail.trim() &&
        billingEmail.trim() === props.company.billingEmail.trim() &&
        address.trim() === props.company.address.trim()) ||
      !accountManager ||
      !primaryEmail ||
      !billingEmail ||
      !address
    );
  };

  const getErrorTypography = () => {
    return (
      <MDTypography sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}>
        This field cannot be empty.
      </MDTypography>
    );
  };

  return (
    <>
      <DialogContent>
        <MDBox px={1.4} pt={1}>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                iVueit Account Manager
              </MDTypography>
              <MDInput
                value={accountManager}
                error={error.accountManager}
                placeholder="Enter Account Manager Name"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value;
                  setCompanyInfo({
                    ...companyInfo,
                    accountManager: newValue,
                  });
                  setError({
                    ...error,
                    accountManager: !newValue
                      ? "This field cannot be empty."
                      : "",
                  });
                }}
                fullWidth
              />
              {error.accountManager && getErrorTypography()}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                Primary Contact Email
              </MDTypography>
              <MDInput
                value={primaryEmail}
                error={error.primaryEmail}
                placeholder="Enter Primary Contact Email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value;
                  setCompanyInfo({
                    ...companyInfo,
                    primaryEmail: newValue,
                  });
                  setError({
                    ...error,
                    primaryEmail: !newValue
                      ? "This field cannot be empty."
                      : "",
                  });
                }}
                fullWidth
              />
              {error.primaryEmail && getErrorTypography()}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                Billing Contact Email
              </MDTypography>
              <MDInput
                value={billingEmail}
                error={error.billingEmail}
                placeholder="Enter Billing Contact Email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value;
                  setCompanyInfo({
                    ...companyInfo,
                    billingEmail: event.target.value,
                  });
                  setError({
                    ...error,
                    billingEmail: !newValue
                      ? "This field cannot be empty."
                      : "",
                  });
                }}
                fullWidth
              />
              {error.billingEmail && getErrorTypography()}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>Address</MDTypography>
              <MDInput
                value={address}
                error={error.address}
                placeholder="Enter Address"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value;
                  setCompanyInfo({
                    ...companyInfo,
                    address: event.target.value,
                  });
                  setError({
                    ...error,
                    address: !newValue ? "This field cannot be empty." : "",
                  });
                }}
                fullWidth
                multiline
                rows="2"
              />
              {error.address && getErrorTypography()}
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={disableSaveButton()}
            onClick={() => {
              props.onSuccess(companyInfo);
              props.closeDialog();
            }}
          >
            SAVE
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default EditContactInfoDialogContent;
