import { CustomIndicator, customBadgeStyle } from "@ivueit/vue-engine";
import MDBox from "components/MDBox";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { CustomAddoption } from "pages/surveys/components/CustomAddOption";
import { tagItemStyle } from "pages/surveys/styles/SurveyStyles";
import { useEffect, useMemo, useState } from "react";
import { createNewTag, getTagsByCompanyID } from "../services/services";
import { WebServiceStatus } from "utils/services/AppUrls";
import { Tag } from "../utlis/interface";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { customTagItemStyle } from "../styles/ClientStyles";

const TagsTabContent = ({ companyID }: { companyID: string }) => {
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [tagsList, setTagsList] = useState<Tag[]>([]);
  const listOfTagNames = useMemo(() => {
    return tagsList.map((tag) => tag.name);
  }, [tagsList]);

  const handleCreateTag = async (optionsList: string[]) => {
    if (!companyID) return;
    setShowLoader(true);
    const newTagName = optionsList[optionsList.length - 1];
    const response = await createNewTag(newTagName, companyID);
    if (response.status === WebServiceStatus.success) {
      const tagID = response.data.id as string;
      if (tagID) {
        fetchTags();
        setSnackBar({
          title: "Success!",
          message: "Tag created successfully",
          isError: false,
        });
      }
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to create new tag : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const fetchTags = async () => {
    if (!companyID) return;
    setShowLoader(true);
    const response = await getTagsByCompanyID(companyID);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.tags as Tag[];
      setTagsList(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to fetch the company tags : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <MDBox pl="16px">
      {showLoader && <CustomIndicator />}
      <MDBox pt={3.2}>
        <CustomAddoption
          onAddOption={handleCreateTag}
          optionsList={listOfTagNames}
        />
      </MDBox>
      <MDBox display="flex" flexWrap="wrap" pt="16px">
        {tagsList.map((tag, index) => {
          return (
            <MDBox key={index} display="flex" sx={customTagItemStyle}>
              <CustomBadge
                content={`${index + 1}. ${tag.name}`}
                borderRadius="8px"
                sx={customBadgeStyle}
              />
            </MDBox>
          );
        })}
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};

export default TagsTabContent;
