import MDBox from "components/MDBox";
import { DraftModePhotoComponent } from "../components/DraftModePhotoComponent";
import { DraftModeVideoComponent } from "../components/DraftModeVideoComponent";
import { DraftModeSelectComponent } from "../components/DraftModeSelectComponent";
import { Card, Dialog, Grid, MenuItem, Switch } from "@mui/material";
import {
  SurveyStepType,
  getSurveyStepType,
} from "pages/dashboard/home/vues/vue_detail/types/types";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import DraftModeSliderComponent from "../components/DraftModeSliderComponent";
import SimpleTextComponent from "../components/SimpleTextComponent";
import { StepActionType, getDeviceOrientationValue } from "../types/enums";
import DraftModeMultiSelectComponent from "../components/DraftModeMultiSelectComponent";
import MDTypography from "components/MDTypography";
import {
  inputNumberStyle,
  labelStyle,
  staticSideNav,
  topBarButtonStyle,
} from "../styles/SurveyStyles";
import MDButton from "components/MDButton";
import {
  CustomDropdown,
  CustomDropdownOption,
  SurveyStep,
  VueSurvey,
} from "@ivueit/vue-engine";
import MDInput from "components/MDInput";
import { mdiPlus } from "@mdi/js";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import {
  getAvailableSurveyTypes,
  getEnumStringValueForTypes,
  getStringValueForTypes,
} from "../utils/constants";
import {
  checkConditionExists,
  checkStepConditionExists,
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
  validateSurvey,
} from "../utils/helpers";
import {
  surveyTemplateReducer,
  SurveyTemplateActionType,
  DEFAULT_STEP,
} from "../reducers/reducer";
import {
  cloneSurveyTemplate,
  deleteSurveyGroups,
  deleteSurveyTemplateStep,
  insertSurveyTemplateStep,
  moveSurveyTemplateStep,
  updateSurveyTemplate,
  updateSurveyTemplateStep,
} from "../services/SurveyServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  GENERIC_ERROR_MESSAGE,
  TEXTFIELD_CHANGE_DELAY,
} from "../../../constants";
import { getCompanyDetailsById } from "pages/dashboard/home/common/services/services";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { DraggableList } from "../components/DraggableList";
import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import Icon from "@mdi/react";
import StyledMenu from "components/CustomComponents/StyledMenu";
import CustomDialogBox from "pages/components/CustomDialogBox";
import EditTagsDialogContent from "../components/EditTagsDialogContent";
import { VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import GroupDialogContent from "../components/GroupDialogContent";
import ConditionsDialogContent from "../components/ConditionsDialogContent";
import { ClientSearchComponent } from "../components/ClientSearchComponent";

interface Props {
  surveyData: VueSurveyData;
  refreshSurveyData: () => void;
}

export const DraftSurvey = ({ surveyData, refreshSurveyData }: Props) => {
  const { survey, groups, conditions, stepConditions } = surveyData;
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [hasEditedCostsOrCompany, setHasEditedCostsOrCompany] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  // This is the step which the user selects to manage group
  const [selectedStep, setSelectedStep] = useState<SurveyStep>(null);
  const [delaySearchAction, setDelaySearchAction] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Company>(null);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [surveyTemplateState, dispatchSurveyTemplateState] = useReducer(
    surveyTemplateReducer,
    survey
  );
  const {
    id,
    canonicalId,
    companyId,
    name,
    description,
    autoSlaHours,
    autoCostPerVue,
    autoPayoutPerVue,
    autoUrgentCost,
    autoUrgentPayout,
    internalNetworkPayout,
    typeOf,
    published,
    archived,
    autoHighQualityRequired,
    autoBackgroundCheckRequired,
    steps,
  } = surveyTemplateState;
  const [openEditTagsDialog, setOpenEditTagsDialog] = useState(false);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const [conditionsDialogStep, setConditionsDialogStep] =
    useState<SurveyStep | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const hasValidCostsAndPayouts =
    autoCostPerVue &&
    autoCostPerVue.value > 0 &&
    autoPayoutPerVue &&
    autoPayoutPerVue.value > 0 &&
    autoUrgentCost &&
    autoUrgentCost.value > 0 &&
    autoUrgentPayout &&
    autoUrgentPayout.value > 0 &&
    internalNetworkPayout &&
    internalNetworkPayout.value >= 0;
  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hasCompanyValue = companyId && companyId.value;
    if (
      hasEditedCostsOrCompany &&
      hasCompanyValue &&
      !hasValidCostsAndPayouts
    ) {
      dispatchSurveyTemplateState({
        type: SurveyTemplateActionType.companyChanged,
        payload: {
          ...surveyTemplateState,
          companyId: null,
        },
      });
      setSnackBar({
        title: "Attention!",
        message:
          "Cost, Payout, Urgent cost, Urgent payout & Internal payout cannot be empty.",
        isError: true,
      });
      setSelectedCompany(null);
    }
  }, [
    autoCostPerVue,
    autoPayoutPerVue,
    autoUrgentCost,
    autoUrgentPayout,
    internalNetworkPayout,
    hasEditedCostsOrCompany,
    companyId,
  ]);

  const handleSetOpenEditTagsDialog = () => {
    setOpenEditTagsDialog(true);
  };

  const getEditTagDialog = () => {
    return (
      <CustomDialogBox
        title={"CONFIGURE TAGS"}
        width="1240px"
        openDialog={openEditTagsDialog}
      >
        <EditTagsDialogContent
          survey={surveyData.survey}
          closeDialog={() => {
            setOpenEditTagsDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const onHeaderButtonClick = async (
    type: StepActionType,
    step: SurveyStep
  ) => {
    const hasSurveyConditions = hasConditionsOnSurvey(surveyData, step);
    switch (type) {
      case StepActionType.group:
        ///TODO - Allow/deny based on specific scenario, for now deny all
        // for stepCondition, ok to group, as it can only currently be linked to an ungrouped condition
        // if condition exists, ok to group if linked step condition step is not in group
        if (hasSurveyConditions) {
          setSnackBar({
            title: "Attention!",
            message: "This step is linked to another, cannot group step.",
            isError: true,
          });
          return;
        }
        setSelectedStep(step);
        setOpenGroupDialog(true);
        break;
      case StepActionType.link:
        setConditionsDialogStep(step);
        break;
      case StepActionType.delete:
        // Handles survey step deletion
        await deleteSurveyStep(step, hasSurveyConditions);
        break;
      default:
        break;
    }
  };

  // handles the survey step deletion
  const deleteSurveyStep = async (
    step: SurveyStep,
    hasSurveyConditions: boolean
  ) => {
    if (hasSurveyConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot delete step.",
        isError: true,
      });
      return;
    }
    const stepId = step.id;
    const surveyId = id;
    setShowLoader(true);
    const response = await deleteSurveyTemplateStep(surveyId, stepId);
    if (response.status === WebServiceStatus.success) {
      // Deletes the survey group, when the step has been deleted
      // and group doesn't contains any other steps
      if (isGroupOrphaned(step, steps)) {
        await deleteSurveyGroupById(step.groupId);
      } else {
        refreshSurveyData();
      }
      setSnackBar({
        title: "Success",
        message: "Survey step deleted successfully.",
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  // Survey group deletion/cleanup should occur when you update the last step
  // referring to the group to remove its groupId or when you delete such a step.
  const deleteSurveyGroupById = async (groupId: string) => {
    setShowLoader(true);
    const response = await deleteSurveyGroups(groupId);
    if (response.status === WebServiceStatus.success) {
      refreshSurveyData();
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const onSucessStepUpdate = (newStep: SurveyStep) => {
    // Updates the survey template on every changes to the survey
    refreshSurveyData();
  };

  const getStepStateContent = (step: SurveyStep, index: number) => {
    const { stepType, groupId } = step;
    const hasBeenGrouped = groupId.isNotEmpty();
    const type = getSurveyStepType(stepType);
    const serialNumber = (index + 1).toString();
    const hasStepConditions = hasConditionsOnSurveyStep(surveyData, step);
    const filteredGroup = groups.filter((group) => group.id === groupId);
    const groupName =
      filteredGroup.length > 0 ? filteredGroup[0].groupTitle : "";
    // No need to display the button for the first item as it cannot be linked
    // TODO: SHOULD HIDE IF ANY PREVIOUS ITEM DO NOT HAVE ANY CONDITIONS
    const showAddConditionButton = index !== 0;
    const groupIndex = groupId
      ? groups.findIndex((group) => group.id === groupId)
      : -1;
    switch (type) {
      case SurveyStepType.photo:
        return (
          <DraftModePhotoComponent
            onHeaderClick={(buttonType: StepActionType) => {
              onHeaderButtonClick(buttonType, step);
            }}
            stepNumber={serialNumber}
            hasBeenGrouped={hasBeenGrouped}
            onSuccess={onSucessStepUpdate}
            step={step}
            surveyData={surveyData}
            onDeleteGroup={async (id: string) => {
              await deleteSurveyGroupById(id);
            }}
            hasConditionsApplied={hasStepConditions}
            groupName={groupName}
            showAddConditionButton={showAddConditionButton}
            groupIndex={groupIndex}
          />
        );
      case SurveyStepType.video:
        return (
          <DraftModeVideoComponent
            onHeaderClick={(buttonType: StepActionType) => {
              onHeaderButtonClick(buttonType, step);
            }}
            surveyData={surveyData}
            onDeleteGroup={async (id: string) => {
              await deleteSurveyGroupById(id);
            }}
            stepNumber={serialNumber}
            hasBeenGrouped={hasBeenGrouped}
            onSuccess={onSucessStepUpdate}
            step={step}
            groupName={groupName}
            hasConditionsApplied={hasStepConditions}
            showAddConditionButton={showAddConditionButton}
            groupIndex={groupIndex}
          />
        );
      case SurveyStepType.select:
        return (
          <DraftModeSelectComponent
            onHeaderClick={(buttonType: StepActionType) => {
              onHeaderButtonClick(buttonType, step);
            }}
            surveyData={surveyData}
            onDeleteGroup={async (id: string) => {
              await deleteSurveyGroupById(id);
            }}
            stepNumber={serialNumber}
            hasBeenGrouped={hasBeenGrouped}
            onSuccess={onSucessStepUpdate}
            step={step}
            groupName={groupName}
            hasConditionsApplied={hasStepConditions}
            showAddConditionButton={showAddConditionButton}
            groupIndex={groupIndex}
          />
        );
      case SurveyStepType.multiSelect:
        return (
          <DraftModeMultiSelectComponent
            serialNumber={serialNumber}
            hasBeenGrouped={hasBeenGrouped}
            onSuccess={onSucessStepUpdate}
            step={step}
            groupName={groupName}
            onHeaderClick={(buttonType: StepActionType) => {
              onHeaderButtonClick(buttonType, step);
            }}
            surveyData={surveyData}
            onDeleteGroup={async (id: string) => {
              await deleteSurveyGroupById(id);
            }}
            hasConditionsApplied={hasStepConditions}
            showAddConditionButton={showAddConditionButton}
            groupIndex={groupIndex}
          />
        );
      case SurveyStepType.slider:
        return (
          <DraftModeSliderComponent
            serialNumber={serialNumber}
            step={step}
            onSuccess={onSucessStepUpdate}
            hasBeenGrouped={hasBeenGrouped}
            onHeaderClick={(buttonType: StepActionType) => {
              onHeaderButtonClick(buttonType, step);
            }}
            surveyData={surveyData}
            onDeleteGroup={async (id: string) => {
              await deleteSurveyGroupById(id);
            }}
            groupName={groupName}
            hasConditionsApplied={hasStepConditions}
            showAddConditionButton={showAddConditionButton}
            groupIndex={groupIndex}
          />
        );
      case SurveyStepType.measurement:
      case SurveyStepType.signature:
      case SurveyStepType.text:
      case SurveyStepType.bool:
      case SurveyStepType.instructions:
        return (
          <SimpleTextComponent
            serialNumber={serialNumber}
            componentType={type}
            onSuccess={onSucessStepUpdate}
            hasBeenGrouped={hasBeenGrouped}
            step={step}
            groupName={groupName}
            onHeaderClick={(buttonType: StepActionType) => {
              onHeaderButtonClick(buttonType, step);
            }}
            surveyData={surveyData}
            onDeleteGroup={async (id: string) => {
              await deleteSurveyGroupById(id);
            }}
            hasConditionsApplied={hasStepConditions}
            showAddConditionButton={showAddConditionButton}
            groupIndex={groupIndex}
          />
        );
      default:
        break;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDelaySearchAction(true);
    const value = event.target.value;
    dispatchSurveyTemplateState({
      type: SurveyTemplateActionType.titleChanged,
      payload: {
        ...surveyTemplateState,
        [event.target.name]: value,
      },
    });
  };

  const handleCostOrSLAInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDelaySearchAction(true);
    const value = parseInt(event.target.value);
    dispatchSurveyTemplateState({
      type: SurveyTemplateActionType.costOrSLAChanged,
      payload: {
        ...surveyTemplateState,
        [event.target.name]: {
          value: value,
        },
      },
    });
  };

  const updateCompanyID = (id: string) => {
    setDelaySearchAction(true);
    if (hasValidCostsAndPayouts) {
      dispatchSurveyTemplateState({
        type: SurveyTemplateActionType.companyChanged,
        payload: {
          ...surveyTemplateState,
          companyId: id ? { value: id } : null,
        },
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message:
          "Cost, Payout, Urgent cost, Urgent payout & Internal payout cannot be empty, if a client is assigned.",
        isError: true,
      });
    }
  };

  const handleToggleChange = (key: string, value: boolean) => {
    setDelaySearchAction(true);
    dispatchSurveyTemplateState({
      type: SurveyTemplateActionType.toggleChanged,
      payload: {
        ...surveyTemplateState,
        [key]: value,
      },
    });
  };

  const handleUpdateSurveyTemplate = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await updateSurveyTemplate(surveyTemplateState, id);
    if (response.status === WebServiceStatus.success) {
      // Updates the survey template on every changes to the survey
      refreshSurveyData();
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyTemplateState, id]);

  const handleInsertNewStep = async (type: SurveyStepType) => {
    var newStep = { ...DEFAULT_STEP, typeOf: type };
    var orientation: {};
    if (type === SurveyStepType.photo) {
      orientation = {
        deviceOrientation: getDeviceOrientationValue("LANDSCAPE"),
      };
      newStep = {
        ...newStep,
        ...orientation,
      };
    } else if (type === SurveyStepType.video) {
      orientation = { deviceOrientation: getDeviceOrientationValue("EITHER") };
      newStep = {
        ...newStep,
        ...orientation,
      };
    } else {
      newStep = { ...newStep };
    }
    const params = {
      atIndex: canonicalId,
      step: newStep,
      useCustomIndex: false,
    };
    setShowLoader(true);
    const response = await insertSurveyTemplateStep(id, params);
    if (response.status === WebServiceStatus.success) {
      refreshSurveyData();
      setSnackBar({
        title: "Success",
        message: "Survey step added successfully.",
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getCompanyDataByID = useCallback(async () => {
    if (!companyId) {
      setSelectedCompany(null);
      return;
    }
    setShowLoader(true);
    const id = companyId.value;
    const response = await getCompanyDetailsById(id);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as Company;
      setSelectedCompany(data);
    } else {
      setSelectedCompany(null);
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const onCloneButtonClick = async () => {
    setShowLoader(true);
    if (!id) {
      setShowLoader(false);
      return;
    }
    const response = await cloneSurveyTemplate(id);
    if (response.status === WebServiceStatus.success) {
      const newSurvey = response.data as VueSurvey;
      setSnackBar({
        title: "Success!",
        message: `New survey #${newSurvey.canonicalId} has been created from this template.`,
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const handleUpdateAndMoveStep = async (
    stepToUpdate: SurveyStep,
    params: any
  ) => {
    const stepID = stepToUpdate.id;
    if (stepID.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await updateSurveyTemplateStep(stepID, stepToUpdate);
    if (response.status === WebServiceStatus.success) {
      const moveResponse = await moveSurveyTemplateStep(id, params);
      if (moveResponse.status === WebServiceStatus.success) {
        refreshSurveyData();
      } else {
        setSnackBar({
          title: "Attention!",
          message: moveResponse.error,
          isError: true,
        });
      }
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const handleDragStep = async (
    stepId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const params = {
      stepId: stepId,
      targetIndex: destinationIndex,
    };
    /// This filters the step which the user is trying to move
    const stepToMove = survey.steps.filter((step) => step.id === stepId)[0];
    const hasConditions = checkConditionExists(conditions, stepToMove);
    const hasStepConditions = checkStepConditionExists(
      stepConditions,
      stepToMove
    );
    // Checking if the step we are about to move is part of any of the conditions/stepConditions.
    // If so, we cannot move the step
    if (hasConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot reorder step.",
        isError: true,
      });
      setShowLoader(false);
      return;
    }

    // Update groupId for the step which we are trying to move
    const targetStepGroupID = survey.steps[destinationIndex].groupId;
    const stepObjWithUpdatedGroupID = {
      ...stepToMove,
      groupId: targetStepGroupID,
    };
    await handleUpdateAndMoveStep(stepObjWithUpdatedGroupID, params);
  };

  useEffect(() => {
    getCompanyDataByID();
  }, [getCompanyDataByID]);

  useEffect(() => {
    if (delaySearchAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyTemplate();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyTemplate, delaySearchAction]);

  const handleStepButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //////// Dialogs //////////////////

  const getCreateGroupDialog = () => {
    return (
      <CustomDialogBox
        title={`${selectedStep.groupId ? "UPDATE" : "CREATE"} GROUP`}
        width="600px"
        openDialog={openGroupDialog}
      >
        <GroupDialogContent
          step={selectedStep}
          closeDialog={(doRefresh: boolean) => {
            setOpenGroupDialog(false);
            if (doRefresh) {
              refreshSurveyData();
            }
          }}
        />
      </CustomDialogBox>
    );
  };

  const getAddConditionsDialog = () => {
    return (
      <Dialog
        open={conditionsDialogStep !== null}
        onClose={null}
        maxWidth="lg"
        fullWidth
      >
        <ConditionsDialogContent
          closeDialog={() => {
            setConditionsDialogStep(null);
          }}
          selectedStep={conditionsDialogStep}
          surveyData={surveyData}
          refreshSurveyData={refreshSurveyData}
        />
      </Dialog>
    );
  };

  const publishSurvey = () => {
    const error = validateSurvey(
      name,
      survey.steps,
      companyId,
      hasValidCostsAndPayouts
    );
    if (!error) {
      dispatchSurveyTemplateState({
        type: SurveyTemplateActionType.statusChanged,
        payload: {
          ...surveyTemplateState,
          published: !published,
        },
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: error,
        isError: true,
      });
    }
  };

  const getAvailableSurveyTypesForDropdown = (): CustomDropdownOption[] => {
    return getAvailableSurveyTypes()
      .filter((type) => type !== "ALL")
      .map((value) => {
        return { value: value, displayTitle: value };
      });
  };

  const getSurveyTypeDropdown = () => {
    const availableOptions = getAvailableSurveyTypesForDropdown();
    const selectedItem = availableOptions.find(
      (option) => option.value === getEnumStringValueForTypes(typeOf)
    );
    return (
      <MDBox>
        <CustomDropdown
          title={"Type"}
          selectedItem={selectedItem}
          availableValues={availableOptions}
          onChange={(newValue: CustomDropdownOption) => {
            const type = getStringValueForTypes(newValue.value);
            setDelaySearchAction(true);
            dispatchSurveyTemplateState({
              type: SurveyTemplateActionType.typeChanged,
              payload: { ...surveyTemplateState, typeOf: type },
            });
          }}
          prefixTitle={true}
        />
      </MDBox>
    );
  };

  return (
    <>
      {openEditTagsDialog && getEditTagDialog()}
      {openGroupDialog && getCreateGroupDialog()}
      {conditionsDialogStep !== null && getAddConditionsDialog()}
      <MDBox display="flex" pb={1.2}>
        <MDBox display="flex" ml="auto" alignItems="center">
          {getSurveyTypeDropdown()}
          <MDBox ml={1.2}>
            <MDButton
              variant="outlined"
              color="info"
              size="medium"
              sx={topBarButtonStyle}
              onClick={() => {
                setDelaySearchAction(true);
                dispatchSurveyTemplateState({
                  type: SurveyTemplateActionType.statusChanged,
                  payload: {
                    ...surveyTemplateState,
                    archived: !archived,
                  },
                });
              }}
            >
              {archived ? "UNARCHIVE" : "ARCHIVE"}
            </MDButton>
          </MDBox>
          <MDBox ml={1.2}>
            <MDButton
              variant="outlined"
              color="info"
              size="medium"
              sx={topBarButtonStyle}
              onClick={onCloneButtonClick}
            >
              MAKE A COPY
            </MDButton>
          </MDBox>
          <MDBox ml={1.2}>
            <MDButton
              variant="contained"
              color="success"
              onClick={publishSurvey}
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              Publish
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card
        sx={{
          minHeight: "calc( 100vh - 155px )",
        }}
      >
        <MDBox px={2.2} pt={2} py={2}>
          <Grid container spacing={2} mb={1.8}>
            <Grid item xs={6}>
              <MDBox mb={1.6}>
                <MDTypography sx={labelStyle}>Vue Title</MDTypography>
                <MDInput
                  value={name}
                  name="name"
                  fullWidth
                  placeholder="Enter Vue title"
                  rows={7}
                  onChange={handleInputChange}
                />
              </MDBox>
              <MDBox>
                <MDTypography sx={labelStyle}>Vue Overview</MDTypography>
                <MDInput
                  value={description}
                  name="description"
                  placeholder="Enter Vue overview"
                  fullWidth
                  multiline
                  rows={3}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" justifyContent="end" pt={1}>
                <CustomBadge
                  content={"Draft"}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white !important",
                      background: "#AE1709",
                      textTransform: "unset",
                    },
                  }}
                />
              </MDBox>
              <MDBox display="flex" flexWrap="wrap" pt={1} justifyContent="end">
                <MDBox display="flex" alignItems="center" ml={1}>
                  <Switch
                    checked={autoHighQualityRequired}
                    onChange={() => {
                      handleToggleChange(
                        "autoHighQualityRequired",
                        !autoHighQualityRequired
                      );
                    }}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ userSelect: "none" }}
                  >
                    High Resolution Images
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={1}>
                  <Switch
                    checked={autoBackgroundCheckRequired}
                    onChange={() => {
                      handleToggleChange(
                        "autoBackgroundCheckRequired",
                        !autoBackgroundCheckRequired
                      );
                    }}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ userSelect: "none" }}
                  >
                    Background Check Required
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox display="flex" flexWrap="wrap">
            <MDBox mb={1} width="130px">
              <MDTypography sx={labelStyle}>SLA Hours</MDTypography>
              <MDInput
                sx={inputNumberStyle}
                value={autoSlaHours?.value ?? ""}
                name="autoSlaHours"
                type="number"
                placeholder="SLA Hours"
                fullWidth
                onChange={handleCostOrSLAInputChange}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
            <MDBox mb={1} width="130px" ml={1.4}>
              <MDTypography sx={labelStyle}>Vue Cost</MDTypography>
              <MDInput
                sx={inputNumberStyle}
                value={autoCostPerVue?.value ?? ""}
                name="autoCostPerVue"
                placeholder="Vue Cost"
                type="number"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHasEditedCostsOrCompany(true);
                  handleCostOrSLAInputChange(event);
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
            <MDBox mb={1} width="130px" ml={1.4}>
              <MDTypography sx={labelStyle}>Vue Payout</MDTypography>
              <MDInput
                sx={inputNumberStyle}
                value={autoPayoutPerVue?.value ?? ""}
                name="autoPayoutPerVue"
                placeholder="Vue Payout"
                type="number"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHasEditedCostsOrCompany(true);
                  handleCostOrSLAInputChange(event);
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
            <MDBox mb={1} width="130px" ml={1.4}>
              <MDTypography sx={labelStyle}>Vue Urgent Cost</MDTypography>
              <MDInput
                sx={inputNumberStyle}
                value={autoUrgentCost?.value ?? ""}
                name="autoUrgentCost"
                placeholder="Urgent Cost"
                type="number"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHasEditedCostsOrCompany(true);
                  handleCostOrSLAInputChange(event);
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
            <MDBox mb={1} width="130px" ml={1.4}>
              <MDTypography sx={labelStyle}>Vue Urgent Payout</MDTypography>
              <MDInput
                sx={inputNumberStyle}
                value={autoUrgentPayout?.value ?? ""}
                name="autoUrgentPayout"
                placeholder="Urgent Payout"
                type="number"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHasEditedCostsOrCompany(true);
                  handleCostOrSLAInputChange(event);
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
            <MDBox mb={1} width="160px" ml={1.4}>
              <MDTypography sx={labelStyle}>
                Internal Network Payout
              </MDTypography>
              <MDInput
                sx={inputNumberStyle}
                value={internalNetworkPayout?.value ?? ""}
                name="internalNetworkPayout"
                placeholder="Internal Network Payout"
                type="number"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHasEditedCostsOrCompany(true);
                  handleCostOrSLAInputChange(event);
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          borderTop="1px solid #C7CCD0"
          borderBottom="1px solid #C7CCD0"
          p="15px 16px 15px"
          position="sticky"
          display="flex"
          top="-1px"
          sx={{
            background: "white",
            zIndex: 102,
            alignItems: "end",
          }}
        >
          <MDBox display="flex" alignItems="end">
            <MDBox width="300px">
              <MDTypography sx={labelStyle}>Client</MDTypography>
              <ClientSearchComponent
                company={selectedCompany}
                onSuccess={(company?: Company) => {
                  const id = company ? company.id : "";
                  setHasEditedCostsOrCompany(true);
                  updateCompanyID(id);
                }}
              />
            </MDBox>
            {companyId && (
              <MDBox ml={1.2}>
                <StyledButtonWithIcon onClick={handleSetOpenEditTagsDialog}>
                  Edit Tags
                </StyledButtonWithIcon>
              </MDBox>
            )}
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              aria-controls={open ? "add-step-dropdown" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              color="success"
              disableElevation
              sx={{ fontSize: "14px", fontWeight: "bold" }}
              onClick={handleStepButtonClick}
              endIcon={<KeyboardArrowDown />}
              startIcon={<Icon path={mdiPlus} size={0.85} />}
            >
              STEP
            </MDButton>
            <StyledMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              {Object.values(SurveyStepType).map((type, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleMenuClose();
                      handleInsertNewStep(type);
                    }}
                    disableRipple
                  >
                    {type === "BOOL" ? "YES/NO" : type}
                  </MenuItem>
                );
              })}
            </StyledMenu>
          </MDBox>
        </MDBox>
        {survey.steps.length > 0 && (
          <MDBox display="flex" minWidth="0">
            <MDBox
              flexShrink={0}
              flexGrow={0}
              flexBasis={"280px"}
              display="flex"
              flexDirection="column"
              sx={staticSideNav}
              ref={listContainerRef}
            >
              <DraggableList
                onDrag={async (
                  stepId: string,
                  sourceIndex: number,
                  destinationIndex: number
                ) => {
                  await handleDragStep(stepId, sourceIndex, destinationIndex);
                }}
                stepList={survey.steps}
                groups={groups}
                onGroupNameEdit={(step: SurveyStep) => {
                  setSelectedStep(step);
                  setOpenGroupDialog(true);
                }}
                onClickListItem={() => {}}
              />
            </MDBox>
            <MDBox flexGrow="1" ref={containerRef}>
              {survey.steps.map((step, index) => {
                return (
                  <MDBox key={index}>{getStepStateContent(step, index)}</MDBox>
                );
              })}
            </MDBox>
          </MDBox>
        )}
      </Card>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};
